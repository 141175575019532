import { useState, useEffect } from "react";
import {
  PayPalScriptProvider,
  PayPalButtons,
} from "@paypal/react-paypal-js";
import { API_PATH, Loader, PAYPAL_CLIENT_ID, PAYPAL_MERCHANT_ID } from '../../utils';
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import { planDatas, selectUserData } from "../../redux/quizSlice/quizSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import accessRoute from "../../route-control/path/public-path";
import { LockClosedIcon } from "@heroicons/react/24/solid";

// This values are the props in the UI
const style = { "label": "paypal", "layout": "vertical" };

export default function PaypalButton() {
  const [loading, setLoading] = useState(false);
  const state = useSelector(selectUserData);
  const planData = state?.planData;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = () => {
    window.dataLayer.push({
      event: "initiated_paypal",
      page: {
        url: window.location.href,
        title: window.location.pathname,
      },
    });
  }

  const onApprove = async (data) => {
    console.log(data)
    // setLoading(true);
    try {
      const response = await axios.post(`${API_PATH}/v2/capture-paypal-order`, {
        session_id: state?.planData?.session_id,
        approved: data
      });
      // console.log(response);

      if (!response.data.success) throw Error("Payment Failed");
      else {
        const updatedState = {
          ...planData,
          txnId: response.data.data.txnId
        }

        dispatch(planDatas(updatedState))
        // navigate to ketoboost
        navigate(accessRoute.KetoBoost.path);
      }
    } catch (e) {
      console.log(e);
      toast(e.message, { type: 'error' });
    } finally {
      setLoading(false);
    }
  }

  async function createOrder() {
    const requestData = {
      session_id: state?.planData?.session_id
    }
    try {
      const response = await axios.post(`${API_PATH}/v2/import-paypal-order`, requestData);
      console.log(response);

      if (!response.data.success) throw Error("Payment Failed");
      else {
        return response.data?.data?.paypalSdkParams?.id;
      }
    } catch (e) {
      console.log(e);
      toast(e.message, { type: 'error' });
    } finally {
      // setLoading(false);
    }
  }

  return (
    <div className="relative">
      <button
        className={`bg-teal-500 text-white p-[12px] rounded-xl font-semibold disabled:bg-gray-300 text-sm mt-3 flex justify-center gap-2 w-full cursor-pointer`}
      >
        {loading ?
          Loader :
          <>
            <LockClosedIcon className="w-4" /> Confirm purchase
          </>
        }
      </button>

      <PayPalScriptProvider
        options={{
          clientId: PAYPAL_CLIENT_ID,
          components: "buttons",
          intent: "capture",
          vault: true,
          currency: 'USD',
          merchantId: PAYPAL_MERCHANT_ID,
        }}
      >
        <PayPalButtons
          style={style}
          className="absolute left-0 top-0 w-full h-full opacity-[0.01]"
          disabled={false}
          fundingSource="paypal" // Available values are: ["paypal", "card", "credit", "paylater", "venmo"]
          forceReRender={[style]}
          createOrder={createOrder}
          onApprove={onApprove}
          onError={(err) => {
            console.log(err)
          }}
          onInit={() => {
            setLoading(false)
          }}
          onClick={handleClick}
        />
      </PayPalScriptProvider>
    </div>
  );
}